
import { defineComponent } from "vue";
import BellOutlineIcon from "icons/BellOutline.vue";
import MagnifyIcon from "icons/Magnify.vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    modelValue: String,
  },
  components: { BellOutlineIcon, MagnifyIcon },
  emits: ["goSearch", "openAlert", "update:modelValue"],
  data() {
    const store = useStore();
    return {
      store,
      unreadCount: 0,
    };
  },
  computed: {
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    change(e: InputEvent) {
      this.$emit("update:modelValue", (e.target as HTMLInputElement).value);
    },
    clickAlert() {
      this.$pushGAEvent("alert_click");
      this.$emit("openAlert");
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("/push/alert/unread/count").then((res) => {
        this.unreadCount = res.data.count;
      });
    }
  },
});
