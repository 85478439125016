
import { defineComponent } from "vue";
import Header from "@/components/urgent/Header.vue";
import UrgentList from "@/components/urgent/List.vue";
import BaseButtonCircle from "@/components/BaseButtonCircle.vue";
import { UrgentListType } from "@/components/urgent/urgent";
import { UrgentListTypeFromJson } from "@/components/urgent/utils";
import { useStore } from "vuex";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import LoadingIcon from "icons/Loading.vue";
import PlusIcon from "icons/Plus.vue";

export default defineComponent({
  name: "Urgent",
  components: {
    Header,
    UrgentList,
    InfiniteLoading,
    LoadingIcon,
    PlusIcon,
    BaseButtonCircle,
  },
  data() {
    const store = useStore();
    return {
      store,
      page: 1,
      isLoading: false,
      infiniteId: +new Date(),
      urgentList: [] as UrgentListType,
    };
  },
  computed: {
    category: {
      get(): string {
        return this.store.getters["urgent/selectedTabName"];
      },
      set(value: string) {
        this.store.dispatch("urgent/updateSelectedTabName", value);
      },
    },
  },
  watch: {
    category(value) {
      this.$pushGAEvent("urgent_category_click", {
        announcement_category: value === "배우" ? "actor" : "staff",
      });
      this.page = 1;
      this.urgentList.splice(0, this.urgentList.length);
      this.infiniteId += 1;
    },
  },
  methods: {
    infiniteHandler($state: any) {
      $state.loading();
      this.reloadUrgents()
        .then((res: any) => {
          $state.loaded();
          if (this.urgentList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    reloadUrgents() {
      this.isLoading = true;
      return this.$axios
        .get("/urgent", {
          params: {
            page: this.page,
            category: this.category,
          },
        })
        .then((res) => {
          const responseData = UrgentListTypeFromJson(res.data.results);
          if (responseData.length > 0) {
            if (this.page == 1) {
              this.urgentList = responseData;
            } else {
              this.urgentList.push(...responseData);
            }
          }
          this.isLoading = false;
          return res;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("showGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("hideGnb");
  },
});
