
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    size: {
      type: String,
      default() {
        return "40px";
      },
    },
    backgroundColor: {
      type: String,
      default() {
        return "var(--blue1-color)";
      },
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4b5ed38f": (_ctx.size),
  "45da42f2": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__